<template>
  <v-footer color="primary" padless>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in links"
        :key="link.name"
        color="goldText"
        text
        rounded
        class="my-2"
        :to="link.url"
      >
        {{ link.name }}
      </v-btn>
      <v-col class="py-4 text-center goldText--text" cols="12">
        <strong
          ><small
            >© TeKanAidLabs {{ new Date().getFullYear() }} — Made with ❤️ at
            TeKanAid Solutions Inc. <br />
            Version {{ version }}</small
          ></strong
        >
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      version: "",
      links: [
        { name: "Home", url: "/" },
        { name: "About", url: "/about" },
        { name: "Terms of Use", url: "/terms" },
        { name: "Privacy Policy", url: "/privacy" },
      ]
    };
  },
  mounted() {
    this.appVersion();
  },
  methods: {
    appVersion() {
      axios
        .get(`${process.env.VUE_APP_API_ADDRESS}/version`)
        .then(res => {
          this.version = res.data;
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
