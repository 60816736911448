<template>
  <v-app>
    <v-app-bar app color="primary" class="goldText--text" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img alt="TeKanAidLabs Logo" class="shrink mr-2" contain src="./assets/tekanaidlabslogo.png"
          transition="scale-transition" width="40" />
        <div>
          <h4 class="d-none d-sm-flex">TeKanAidLabs</h4>
        </div>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" temporary app dark color="primary">
      <v-list>
        <v-list-item class="goldText--text" v-for="item in items" :key="item.title" :to="item.route"
          :disabled="item.disabled"
          v-if="item.title === 'Account' ? isUserAuthenticated : (item.title !== 'Login' && item.title !== 'Register') || !isUserAuthenticated">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list-item class="goldText--text" v-if="isUserAdmin" :to="'/admin'">
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Admin</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-spacer></v-spacer>

      <v-list>
        <Logout v-if="isUserAuthenticated" />
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
    <my-footer />
  </v-app>
</template>

<script>
import MyFooter from "./components/MyFooter.vue";
import Logout from "@/components/Logout.vue";

export default {
  components: { MyFooter, Logout },
  data() {
    return {
      myCallbacks: {
        onPreviousStep: this.myCustomPreviousStepCallback,
        onNextStep: this.myCustomNextStepCallback,
      },
      myOptions: {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: "Skip tour",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Finish",
        },
      },
      steps: [
        {
          target: ".mdi-menu",
          header: {
            title: "Get Started",
          },
          content: `This is the navigation window`,
          params: {
            highlight: true,
          },
        },
        {
          target: "#add-bank",
          content: "You can link a bank account here",
          params: {
            highlight: true,
          },
        },
        {
          target: "#logout",
          content: "This is where to logout",
          params: {
            highlight: true,
            placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
      ],
      items: [
        { title: "Home", icon: "mdi-home", route: "/", plan: "free" },
        {
          title: "About",
          icon: "mdi-information",
          route: "/about",
          plan: "free",
        },
        {
          title: "Pricing",
          icon: "mdi-currency-usd",
          route: "/pricing",
          plan: "free",
        },
        {
          title: "Register",
          icon: "mdi-account-circle",
          route: "/register",
          plan: "free",
        },
        {
          title: "Login",
          icon: "mdi-account-check",
          route: "/login",
          plan: "free",
        },
        {
          title: "Account",
          icon: "mdi-account-details",
          route: "/account",
          plan: "free",
        }
      ],
      right: null,
      drawer: false,
      group: null,
    };
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters.isUserAdmin;
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    isUserAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    }
  },
  mounted() {
    // Add listeners
    document.addEventListener('mousemove', this.resetTimer);
    document.addEventListener('keydown', this.resetTimer);
  },
  beforeDestroy() {
    // Remove listeners
    document.removeEventListener('mousemove', this.resetTimer);
    document.removeEventListener('keydown', this.resetTimer);
  },
  methods: {
    resetTimer() {
      this.$store.dispatch('resetTimer');
    },
    myCustomPreviousStepCallback(currentStep) {
      if (currentStep === 1) {
        this.drawer = true;
      }
    },
    myCustomNextStepCallback(currentStep) {
      if (currentStep === 0) {
        this.drawer = true;
      }
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
<style scoped>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>
