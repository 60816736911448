<template>
  <v-container grid-list-xs>
    <welcome />
    <oauth />
    <v-card v-if="isUserAuthenticated" class="mb-5">
      <v-card-title>Your Current Plan: {{ plan }}</v-card-title>
      <v-card-text>
        <p :class="{ 'red-text': isMaxed }">Usage Count: {{ usageCount }} / {{ maxUsage }} </p>
        <p :class="{ 'red-text': isMaxed }" v-if="isMaxed">You've reached your maximum usage for the month. You can
          upgrade if you'd like.</p>
        <v-btn v-if="isMaxed" color="secondary" @click="goToAccount">Usage Reached Upgrade
          Now</v-btn>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-btn color="primary" @click="handleButtonClick">{{ buttonText }}</v-btn>
      <p>
        Don't have an account?
        <router-link to="/register" class="mt-3">Create an account</router-link>
      </p>
    </div>
    <v-container grid-list-xs>
      <v-card>
        <v-card-title>
          Video Guide
        </v-card-title>
        <v-card-text>
          <v-btn @click="toggleVideo" color="secondary">
            {{ showVideo ? 'Hide Video' : 'Show Video' }}
          </v-btn>
          <div v-if="showVideo">
            <YouTubeEmbed videoId="th288u_aLVw" />
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import Welcome from "@/components/Welcome.vue";
import { mapGetters } from "vuex";
import YouTubeEmbed from "@/components/YouTubeEmbed.vue";
import oauth from "@/components/oauth.vue";

export default {
  name: "Home",
  data() {
    return {
      showVideo: true,
    };
  },
  components: {
    Welcome,
    YouTubeEmbed,
    oauth
  },
  computed: {
    ...mapGetters([
      'isUserAuthenticated',
      'user',
      'usageCount',
      'plan',
      'isOriginalPlanActive',
      'maxUsage',
      'hasExceededUsageLimit',
      'isMaxed'
    ]),
    buttonText() {
      if (this.isUserAuthenticated && !this.user.emailVerified) {
        return "Verify Email";
      }
      return "Login for Access";
    }
  },
  methods: {
    toggleVideo() {
      this.showVideo = !this.showVideo;
    },
    handleButtonClick() {
      if (this.isUserAuthenticated && !this.user.emailVerified) {
        // Navigate to a page where the user can verify their email
        this.$router.push('/verify-email');
      } else {
        // Navigate to the login page
        this.$router.push('/login');
      }
    },
    goToAccount() {
      this.$router.push('/account');
    }
  }
};
</script>

<style scoped>
.red-text {
  color: red;
}
</style>