<template>
    <div>
      <v-btn @click="initiateOAuthFlow" color="success">Connect to Clixlo</v-btn>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    methods: {
      initiateOAuthFlow() {
        // Directly change the window location to start the OAuth flow
        window.location.href = `${process.env.VUE_APP_API_ADDRESS}/clixlo/auth`;
      },
      exchangeCodeForToken(code) {
        // Ensure this action is secure and consider moving sensitive operations to backend
        axios.get(`${process.env.VUE_APP_API_ADDRESS}/clixlo/auth/callback`, { params: { code } })
          .then(response => {
            // Optionally store the access token in Vuex store or local storage
          })
          .catch(error => {
            console.error('Error exchanging code for token:', error);
          });
      }
    },
    created() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        // Call exchangeCodeForToken directly with the code
        this.exchangeCodeForToken(code);
      }
    }
  };
  </script>
  